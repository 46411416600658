import { css } from '@emotion/css';

const flexStyles = {
  display: css`
    display: flex;
  `,
  alignItems: {
    center: css`
      align-items: center;
    `,
  },
  justifyContent: {
    between: css`
      justify-content: space-between;
    `,
  },
};

export default flexStyles;
